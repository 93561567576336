<script setup>
    import { ref } from 'vue';

    const showModal = ref(false);

    function openModal() {
        showModal.value = true;
    }
    function closeModal() {
        showModal.value = false;
    }
</script>
<template>
  <section class="home--intro">
    <div class="home--intro-wrapper container">
      <div class="home--intro-row">
        <div class="col-lg-4 col-12 col-row">
          <div class="home--intro-block-title">
            {{ $t('home_intro.block_1_title') }}
          </div>
          <div class="home--intro-block-content">
            {{ $t('home_intro.block_1_text_1') }}
          </div>
          <div class="home--intro-block-content">
            <span class="home--intro-block-content-bold">
              {{ $t('home_intro.block_1_text_2_bold') }}
            </span>
            {{ $t('home_intro.block_1_text_2') }}
          </div>
          <div class="home--intro-block-content">
            <span class="home--intro-block-content-bold">
              {{ $t('home_intro.block_1_text_3_bold') }}
            </span>
            {{ $t('home_intro.block_1_text_3') }}
          </div>
          <div class="home--intro-block-content">
            <span class="home--intro-block-content-bold">
              {{ $t('home_intro.block_1_text_4_bold') }}
            </span>
            {{ $t('home_intro.block_1_text_4') }}
          </div>
          <div class="home--intro-block-content">
            <span class="home--intro-block-content-bold">
              {{ $t('home_intro.block_1_text_5_bold') }}
            </span>
            {{ $t('home_intro.block_1_text_5') }}
          </div>
        </div>
        <div class="col-lg-4 col-12 col-row">
          <div class="col-row-intro-inner-wrapper">
            <div class="home--intro-block-title">
              {{ $t('home_intro.block_2_title') }}
            </div>
            <div class="home--intro-block-content">
              {{ $t('home_intro.block_2_text_1') }}
            </div>
            <div class="home--intro-block-content">
              {{ $t('home_intro.block_2_text_2') }}
              <span class="home--intro-block-content-bold">
                {{ $t('home_intro.block_2_text_2_bold') }}
              </span>
            </div>
            <div class="home--intro-block-content">
              <span class="home--intro-block-content-bold">
                {{ $t('home_intro.block_2_text_3_bold') }}
              </span>
              {{ $t('home_intro.block_2_text_3_1') }}
            </div>
            <div class="home--intro-block-content">
              {{ $t('home_intro.block_2_text_4') }}
              <span class="home--intro-block-content-bold">
                {{ $t('home_intro.block_2_text_4_bold') }}
              </span>
            </div>
            <div class="home--intro-block-content">
              {{ $t('home_intro.block_2_text_5') }}
              <span class="home--intro-block-content-bold">
                {{ $t('home_intro.block_2_text_5_bold') }}
              </span>
              {{ $t('home_intro.block_2_text_5_1') }}
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-12">
          <div class="home--intro-block-title">
            {{ $t('home_intro.block_3_title') }}
          </div>
          <div class="home--intro-block-content content-fake">
            {{ $t('home_intro.block_3_text_1') }}
          </div>
          <div class="home--intro-block-content">
            <span class="home--intro-block-content-bold">
              {{ $t('home_intro.block_3_text_2') }}
            </span>
          </div>
          <div class="home--intro-block-title">
            {{ $t('home_intro.block_3_title_2') }}
          </div>
          <div class="home--intro-block-content">
            {{ $t('home_intro.block_3_text_2_1') }}
            <span class="home--intro-block-content-bold">
              <a href="https://cloudactivereception.com" target="_blank">
                {{ $t('home_intro.block_3_text_2_2') }}
              </a>
            </span>
          </div>
        </div>
      </div>
      <div class="home--intro-block-button">
        <div class="home--intro-block-button-inner">
          <button class="custom-btn custom-btn-inverted custom-intro-btn" @click="openModal">
            {{ $t('home_intro.button_text') }}
          </button>
        </div>
      </div>
    </div>
  </section>
  <transition name="modal">
    <div class="modal__template" v-if="showModal">
        <div class="modal-mask">
            <div class="modal-wrapper" @click="closeModal">
                <div class="modal-container" @click.stop="">
                    <div class="modal-header">
                        <slot name="header"> 
                            <div class="">
                                <h3>
                                    <button class="modal-close-button"
                                        @click="() => showModal = false">
                                        X
                                    </button>
                                </h3>
                            </div>
                        </slot>
                    </div>
                    <div class="modal-body">
                        <slot name="body"> 
                          <iframe width="700" height="400" src="https://www.youtube.com/embed/wPSHMJilY_s?si=PjC4c8U7veP2-u6L" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </slot>
                    </div>
                    <div class="modal-footer">
                        <slot name="footer"> </slot>
                    </div>
                </div>
            </div>
        </div>
    </div>
</transition>
</template>
<style lang="scss" scoped>
.home--intro {
  background-color: var(--car-factura-primary);
  background-color: rgb(111, 128, 154, 0.63);
  padding-top: 90px;
  padding-bottom: 70px;
  .home--intro-wrapper {
    .home--intro-block-title {
      font-size: 26px;
      letter-spacing: -1.3px;
      color: white;
      font-family: 'CircularStd-Black';
      padding-bottom: 20px;
    }
    .home--intro-block-content {
      font-size: 16px;
      letter-spacing: -0.8px;
      padding-bottom: 20px;
      .home--intro-block-content-bold {
        font-family: 'CircularStd-Bold';
        a {
          text-decoration: none;
          color: black;
          display: block;
          transition: 0.5s;
          transition: white 100ms linear;
          position: relative;
          width: fit-content;
          &:before {
            content: '';
            position: absolute;
            width: 100%;
            height: 1px;
            border-radius: 4px;
            background-color: black;
            bottom: -2px;
            left: 0;
            transform-origin: right;
            transform: scaleX(0);
            transition: transform 0.3s ease-in-out;
          }
          &:hover:before {
            transform-origin: left;
            transform: scaleX(1);
          }
        }
      }
      &.content-fake {
        padding-bottom: 5px;
      }
    }
    .home--intro-row {
      display: flex;
      flex-direction: row;
      gap: 67px;
      max-width: 1065px;
      margin: 0 auto;
    }
  }
}

.home--intro-block-button {
  display: flex;
  justify-content: center;
  max-width: 900px;
  margin: 0 auto;
  .home--intro-block-button-inner {
    padding-top: 30px
  }
}

.modal__template {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 770px;
  height: 482px;
  margin: 0px auto;
  padding: 20px 35px;
  background-color: black;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}
.modal-header {
  justify-content: flex-end;
}
.modal-header h3 {
  margin-top: 0;
}

.modal-body,
.modal-footer {
  margin: 5px 0 15px;
}

.modal-enter-from,
.modal-leave-to {
  opacity: 0;
}

.modal-enter-active .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.modal-close-button {
  float: right;
  color: white;
  background-color: black;
  border: none;
  font-size: 17px;
}
@media screen and (max-width: 1000px) {
  .home--intro {
    background-color: var(--car-factura-primary);
    background-color: rgb(111, 128, 154, 0.63);
    padding-top: 90px;
    padding-bottom: 53px;
    .home--intro-wrapper {
      .home--intro-block-title {
        font-size: 18px;
        letter-spacing: -1.3px;
        color: white;
        font-family: 'CircularStd-Black';
        padding-bottom: 10px;
      }
      .home--intro-block-content {
        font-size: 15px;
        letter-spacing: -0.8px;
        padding-bottom: 15px;
        .home--intro-block-content-bold {
          font-family: 'CircularStd-Bold';
        }
        &.content-fake {
          padding-bottom: 5px;
        }
      }
      .home--intro-row {
        display: block;
        max-width: 100%;
        margin: 0 auto;
        .col-row {
          padding-bottom: 30px;
        }
      }
    }
  }
}
@media screen and (max-width: 800px) {
  .home--intro-block-button {
    .home--intro-block-button-inner {
      margin-left: 0;
    }
  }
}
</style>
